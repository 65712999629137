import React from 'react'
import {Link} from 'react-router-dom'
import Plx from 'react-plx'
import Typewriter from 'typewriter-effect';
import Page from '/src/components/Page/Page'
import Ticker from 'react-ticker'
import Cube from '/src/components/Cube/Cube'

import HomeIndex from './HomeIndex'

// SWIPER
import {Swiper, SwiperSlide} from 'swiper/react';
//import SwiperCore, { Autoplay, Navigation } from 'swiper';
//SwiperCore.use([Autoplay, Navigation]);

import { Autoplay, Navigation } from 'swiper/modules';

/* PLX

  More options:
  https://www.npmjs.com/package/react-plx

*/

/* TYPEWRITER

  https://www.npmjs.com/package/typewriter-effect

*/


class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      scrollRatio: 5,
      test: "test state"
    }
  }


  componentDidMount() {
    document.body.classList.add('is-home');
  }

  componentWillUnmount() {
    document.body.classList.remove('is-home');
  }

  render() {


    return (
      <Page>


        <div className="site-grid site-grid-home site-grid-home-argentina">



          <div className="item item-symbol">
            <div className="item-inner">
              <img src="/img/dd/logo-dd-241130.svg" />
            </div>
          </div>

          <div className="item item-text">
            <div className="item-inner">
              <p>Queridos amigos,<br /><br />Estamos muy contentos de invitarlos a nuestra fiesta de casamiento para celebrar nuestro amor con ustedes.</p>

              <p><br />
                <center>
                  Sábado<br />
                  11 de enero, 2025<br />
                  20:30 h
                </center>
              </p>

              <p><br />
                <center>
                  <a href="https://maps.app.goo.gl/B7xUcfNN2j7sn2QR8" target="_blank">Zeta Campo<br />
                  Chacabuco, Buenos Aires<br />
                  Argentina</a>
                </center>
                <br />
              </p>

              <p>Los esperamos en Chacabuco para disfrutar juntos.</p>

              <p>Con amor,<br />
              Denny + Dino
              </p>

            </div>
          </div>



          <div className="item item-text item-text-faq">
            <div className="item-inner">
              <p><center><em>The Chongos&rsquo; F.A.Q.</em></center></p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Cuál es el código de vestimenta?</p>
              <p>Vestimenta formal, cocktail</p>
              <p>Si eligen ir de traje, puede ser de cualquier color y pueden optar por usar corbata o no. Los vestidos pueden ser largos o cortos, o pueden optar por ir de pantalón o pollera. Lo importante es que todos se sientan cómodos.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Puedo ir con un acompañante?</p>
              <p>Podés ir con un acompañante sólo si tu invitación dice +1.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Los niños son bienvenidos?</p>
              <p>Amamos a sus niños, pero no están invitados al casamiento. Por favor, contactate con nosotros si tienen preguntas al respecto.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Qué pasa si tengo alguna restricción dietaria?</p>
              <p>Al momento de confirmar tu asistencia, por favor, informanos si tenés alguna restricción dietaria.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Cómo puedo ir a Zeta Campo?</p>
              <p>Pueden ir en su propio auto o podemos reservar un remis para ustedes.</p>
              <p>Si se hospedan en el Hotel Postal, habrá buses que los llevarán a Zeta Campo. Recibirán más información cuando hagan el check-in.</p>

              <p><a href="https://maps.app.goo.gl/SnUq3oE2CwHeMV1h8" target="_blank">Link a Google Maps:<br /><i>Zeta Campo</i></a></p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Cómo suele ser el clima en enero en Chacabuco?</p>
              <p>Durante el día, es probable que haga mucho calor. En enero, la temperatura puede pasar los 30 grados. Por la noche/madrugada, suele refrescar y la temperatura podría bajar hasta los 18 grados.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿La boda será al aire libre?</p>
              <p>Sí, es por eso que sugerimos que lleven abrigo.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Qué tipo de zapatos debería usar?</p>
              <p>Toda la fiesta será sobre el pasto, así que les sugerimos que elijan sus zapatos en base a eso.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿A qué hora debo llegar?</p>
              <p>El horario de inicio de la boda es a las 20:30 h, y les sugerimos que sean puntuales.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">¿Hay alguna lista de regalos de casamiento?</p>
              <p>Decidimos no hacer lista de regalos de casamiento. Si desean hacernos un regalo, aceptamos contribuciones para nuestra luna de miel.</p>

              <p>Si transferís en pesos argentinos, podés hacerlo a esta cuenta:<br />
              <i>Cta: 118-35048/0<br />
                CBU: 0170118640000003504804<br />
                Alias: DINO.BA</i></p>


              <p>Si transferís en euros, podés hacerlo a esta cuenta:<br />
              <i>Account name: D. Pettinaroli<br />
                Bank: ING Bank<br />
                IBAN: NL95 INGB 0107 4765 41<br />
                BIC: INGBNL2A</i></p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q">Si tengo alguna otra pregunta, ¿a quién puedo contactar?</p>
              <p>Pueden enviarnos un email a Dino vía <a href="mailto:dinopettinaroli@gmail.com">dinopettinaroli@gmail.com</a> o a Denny vía <a href="mailto:dennybackhaus@gmail.com">dennybackhaus@gmail.com</a>.</p>
            </div>
          </div>


          <div className="item item-text item-text-faq-question">
            <div className="item-inner">
              <p className="faq-q"></p>
              <p></p>
            </div>
          </div>





        </div>



      </Page>
    )
  }
}

export default Home
