import React, { useContext } from 'react'
import {Link, Routes, Route, useLocation} from 'react-router-dom' // Need this every time we want to move to different route
import {AppContext, AppContextConsumer} from '/src/components/Context/appContext'
import { AnimatePresence } from 'framer-motion'

/*

New Routes
https://github.com/remix-run/react-router/blob/f59ee5488bc343cf3c957b7e0cc395ef5eb572d2/docs/advanced-guides/migrating-5-to-6.md#relative-routes-and-links

*/

// Helpers
import WhenChangeRoute from './components/Helpers/WhenChangeRoute'

// Components
import Header from './components/Header/Header'
import Footer from './components/Footer/Footer'

// Pages
import Home from './pages/Home/Home'
import HomeAmsterdam from './pages/Home/Home-Amsterdam'



// REACT ROUTER

function App() {

  let location = useLocation();

  const locationKey = (location.pathname.includes('/pdp')) ? 'false' : location.pathname;

  const isSearch = (location.pathname.includes('/search')) ? true : false;

  const myContext = useContext(AppContext);



  return (
    <React.Fragment>
      <Header />

        <AnimatePresence
          exitBeforeEnter
          initial={false}
          onExitComplete={() => {window.scrollTo(0, 0); if (!isSearch) myContext.closeSearch(); }}
          >

          <Routes location={location} key={locationKey}>


            <Route path="/" element={<Home />} />
            <Route path="/amsterdam" element={<HomeAmsterdam />} />

          </Routes>

        </AnimatePresence>

      <Footer />
    </React.Fragment>
  )
}

export default App
