import React from 'react'
import {Link} from 'react-router-dom'
import {AppContext} from '/src/components/Context/appContext'
import Modal from 'react-modal';
import ModalCountry from '/src/components/Modal/ModalCountry'
import WidgetNewsletter from '../Newsletter/WidgetNewsletter'

import mobileFooterData from './mobileFooterData'


class Footer extends React.Component {

  static contextType = AppContext;

  state = {
    modalCountryIsOpen: false,
    isOpenKey: false,
    mobileFooterData: mobileFooterData,
  };

  openCountryModal = () => {
    this.context.openCountryModal();
  }

  closeCountryModal = () => {
    this.context.closeCountryModal();
  }


  toggleSubmenu = (key) => {
    this.setState(prevState => ({ isOpenKey: prevState.isOpenKey === key ? false : key }))
  }

  closeSubmenu = (key) => {
    this.setState({ isOpenKey: false })
  }






  render() {






    return (

      <footer>

        <Modal
          isOpen={this.context.state.modalCountryIsOpen}
          onRequestClose={this.closeCountryModal}
          overlayClassName="overlay overlay-country"
          className="overlay-panel"
          closeTimeoutMS={800}
        >
          <div className="overlay-close" onClick={this.closeCountryModal}>×</div>
          <div className="overlay-panel-inner">
            <ModalCountry />
          </div>
        </Modal>






        {/*
        <div className="logo">
          <img src="/img/fm-logo-beta.svg" />
        </div>
        */}




        <div className="links links-secondary">
          <ul className="links-3col links-horizontal links-fm-copyright">
            <li><a href="http://www.denny-dino.com" target="_blank"><span>Denny + Dino</span></a></li>
          </ul>
          <ul className="links-6col links-centeralign links-fm-ends">
            <li></li>
          </ul>
          <ul className="links-3col links-title links-rightalign links-fm-follow">
            <li></li>
          </ul>

        </div>






      </footer>

    )
  }
}


export default Footer
